<template>
  <div class="index">
    <div class="row">
      <div class="col-3"></div>

      <div class="col-6 recipe-home">
        <div class="create_form">
          <div class="pt-4 pb-4">
            <h4>Kontaktformular für sonstige Anfragen</h4>
          </div>
          <form
            @submit.prevent="sendContact"
            class="form"
            enctype="multipart/form-data"
          >
            <div class="name_form">
              <label for="img"
                >Vorname und Nachname <em style="color: red"> *</em></label
              >
              <br />
              <input
                type="text"
                placeholder="Vorname und Nachname"
                v-model="name"
                @blur="$v.name.$touch"
              />
              <template v-if="$v.name.$error">
                <div v-if="!$v.name.required" class="error-message">
                  müssen ausgefüllt werden!
                </div>
              </template>
            </div>
            <div class="name_form">
              <label for="img">
                Email
                <em style="color: red"> *</em>
              </label>
              <br />
              <input
                type="email"
                placeholder="max@muster.de"
                v-model="email"
                @blur="$v.email.$touch"
              />
              <template v-if="$v.email.$error">
                <div v-if="!$v.email.required" class="error-message">
                  müssen ausgefüllt werden!
                </div>
                <div v-if="!$v.email.email" class="error-message">
                  falsche email format!
                </div>
              </template>
            </div>

            <div class="name_form">
              <label for="img">Telephone <em style="color: red"> *</em></label>
              <br />
              <input
                type="number"
                placeholder="0123 456789"
                v-model="telephone"
              @blur="$v.telephone.$touch"
              />
              <template v-if="$v.telephone.$error">
                <div v-if="!$v.telephone.required" class="error-message">
                  müssen ausgefüllt werden!
                </div>
              </template>
            </div>
            <div class="description_form">
              <label for="img"
                >Ihre Nachricht <em style="color: red"> *</em></label
              >
              <br />
              <textarea
                type="text"
                rows="10"
                placeholder="Ihre Nachricht ..."
                v-model="description"
                @blur="$v.description.$touch"
              ></textarea>
              <template v-if="$v.description.$error">
                <div v-if="!$v.description.required" class="error-message">
                  müssen ausgefüllt werden!
                </div>
                <div v-if="!$v.description.minLength" class="error-message">
                  Nachricht muss mindenstens 20 Zeichen werden!
                </div>
              </template>
            </div>
            <div class="">
              <div>
                <h6>
                  Einwilligung Speicherung Daten <em style="color: red"> *</em>
                </h6>
              </div>
              <div>
                <p style="font-size: small">
                  Im Rahmen der europäischen Datenschutz-Grundverordnung (DSGVO)
                  müssen sie dem folgenden Punkt zustimmen.
                </p>
              </div>
              <div class="pb-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    <p style="font-size: small">
                      Ich stimme zu, dass meine Daten zur Bearbeitung meiner Anfrage gespeichert werden. Mir ist bewusst, dass ich jederzeit meine Daten nach schriftlicher Aufforderung löschen lassen kann.
                    </p>
                  </label>
                </div>
              </div>
            </div>
            <div class="submit_form">
              <button value="Create" type="submit">Senden</button>
            </div>
            <div v-if="message" class="pt-3">
              <p>{{ message }}</p>
            </div>
          </form>
        </div>
      </div>
      <div class="col-3 recipe-right"></div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

import EmailService from "../services/email.service";

export default {
  mixins: [validationMixin],
  data() {
    return {
      message: "",

      name: "",
      email: "",
      description: "",
      telephone: "",
    };
  },
  validations: {
    email: {
      email,
      required,
    },
    name: {
      required,
    },
    telephone: {
      required,
    },
    description: {
      required,
      minLength: minLength(20),
    },
  },
  methods: {
    async messageTimeOut() {
      await setTimeout(() => {
        this.message = "";
        this.$router.push("/");
      }, 3000);
    },
    sendContact() {
      this.message = "Bitte warten...";

      EmailService.sendContact(
        this.name,
        this.email,
        this.description,
        this.telephone
      ).then(() => {
        this.message = "Ihre Nachricht ist bei uns eingegangen";
        this.messageTimeOut();
      });
    },
  },
};
</script>

<style scouped>
input,
textarea {
  outline: none;
}
.index {
  padding: 1rem;
}
.error-message {
  color: red;
}

.register-icon {
  color: #7e8ba3;
  text-align: center;
}

.register-icon h1 {
  font-family: cursive;
  font-size: x-large;
}

.create_form {
  /* background-color:rgba(196, 191, 191, 0.1); */
  background: #fbfbfb;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
}

/* Input */
.hide {
  display: none;
}
.name_form {
  padding-bottom: 10px;
}
.name_form > input {
  padding: 10px;
  width: 80%;
  border-radius: 15px;
  /* background-color: #f5f5f5; */
  border: ridge;
}
.name_form > input:hover {
  background-color: white;
}

.description_form {
  padding-bottom: 10px;
  padding-top: 10px;
}
.description_form > textarea {
  padding: 15px;
  width: 80%;
  border-radius: 15px;
  /* background-color: #f5f5f5; */
  border: ridge;
}

.description_form > textarea:hover {
  background-color: white;
}

.text_description {
  font-size: x-small;
}
.submit_form > button {
  cursor: pointer;
  color: black;
  margin: 0 auto;
  padding: 1px;
  width: 40%;
  border-radius: 15px;
  background-color: #f5f5f5;
  border: ridge;
}

.submit_form > button:hover {
  background-color: #7e8ba3;
}

/* LOADER */
.loader {
  margin: 0 auto;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 45px;
  height: 45px;
  /* margin: -76px 0 0 -76px; */
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #302f2f;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 576px) {
  .recipe-home {
    max-width: 100% !important;
    flex: 100% !important;
  }

  .recipe-right {
    display: none;
  }

  .create_form {
    padding: 0;
  }
}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .recipe-home {
    max-width: 100%;
    flex: 100%;
  }

  .recipe-right {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .recipe-home {
    max-width: 66%;
    flex: 66%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .recipe-home {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .recipe-right {
    display: block;
  }
}
</style>
